<template>
  <div class="searchIndex">
    <div style="height: 5.625rem"></div>
    <!-- 搜索/相关搜索 -->
    <div class="px-315 py-30 border-bottom border-ligit-BFBFBF">
      <!-- 搜索框 -->
      <div class="flex a-center cursorp mb-20">
        <el-input
          v-model="keyword"
          placeholder="请输入关键词"
          @keyup.enter.native="search()"
        >
          <div
            class="flex a-center search justify-center"
            slot="append"
            @click="search()"
          >
            <div class="mr-10">
              <i class="el-icon-search"></i>
            </div>
            搜索
          </div>
        </el-input>
      </div>
      <!-- 相关搜索 -->
      <div class="flex font-12 flex-wrap">
        <div class="mr-10 text-333333">相关搜索：</div>
        <div
          v-for="(item, index) in Searchhistory"
          :key="index"
          class="mr-10 text-999999 cursorp"
          @click="history(item.name)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 搜索类型 -->
    <div class="py-30 px-315 flex align-center border-bottom border-ligit-BFBFBF">
      <div
        class="font-weight-bolder mr-40 cursorp"
        @click="cuttype('index')"
        :class="type == 'index' ? 'text-FF524C font-28' : 'text-333333 font-26'"
      >
        电影赏析
      </div>
      <div
        class="font-weight-bolder cursorp"
        :class="type == 'creation' ? 'text-FF524C font-28' : 'text-333333 font-26'"
        @click="cuttype('creation')"
      >
        原创视频
      </div>
    </div>
    <!-- 内容1-电影赏析 -->
    <div class="py-30 px-315" v-if="type == 'index'">
      <div
        v-for="(item, index) in film"
        :key="index"
        class="flex align-center py-35 border-bottom border-F0F0F0"
        @click="gofilmdetails(item.id)"
      >
        <div style="width: 10.3125rem; height: 14.25rem">
          <el-image
            class="rounded3"
            style="width: 10.3125rem; height: 14.25rem"
            :src="item.img_shu"
            fit="cover"
          ></el-image>
        </div>
        <!-- <img :src="item.img_shu" alt="" width="10.3125rem" height="14.25rem" class="rounded3" /> -->
        <div class="ml-63">
          <div class="flex align-end mb-30">
            <div class="font-26 font-weight-bolder">
              <span v-html="richNodes(item.name)"></span>
            </div>
            <div class="font-14 text-666666 ml-20">
              好评指数 <span class="text-FF615B">{{ item.star }}分</span>
            </div>
          </div>
          <div class="mb-35">
            <div class="font-14 text-222222 font-weight-bolder mb-20">本片介绍</div>
            <div class="font-14 text-666666 text-ellipsis2">
              {{ item.introduce }}
            </div>
          </div>
          <div class="font-14 text-222222 flex align-center">
            <!-- <img
              src="@/assets/img/Playimmediately.png"
              alt=""
              width="9rem"
              height="3.25rem"
              class="cursorp"
            /> -->
            <el-image
              class="cursorp"
              style="width: 9rem; height: 3.25rem"
              :src="require('@/assets/img/Playimmediately.png')"
              fit="cover"
            ></el-image>
            点播次数：{{ item.play_nums }}
          </div>
        </div>
      </div>
    </div>
    <!-- 内容2-原创视频 -->
    <div class="py-30 px-315" v-else>
      <div
        v-for="(item, index) in film"
        :key="index"
        class="flex align-center py-35 border-bottom border-F0F0F0"
        @click="godetails(item.id)"
      >
        <div style="width: 10.3125rem; height: 14.25rem">
          <el-image
            class="rounded3"
            style="width: 10.3125rem; height: 14.25rem"
            :src="item.img"
            fit="cover"
          ></el-image>
        </div>
        <!-- <img :src="item.img_shu" alt="" width="10.3125rem" height="14.25rem" class="rounded3" /> -->
        <div class="ml-63">
          <div class="flex align-end mb-30">
            <div class="font-26 font-weight-bolder">
              <span v-html="richNodes(item.title)"></span>
            </div>
          </div>
          <div class="mb-35">
            <div class="flex align-center mb-20">
              <div class="font-14 text-222222 font-weight-bolder mr-30">作者介绍</div>
              <div class="flex align-center">
                <el-image
                  style="
                    border-radius: 50%;
                    width: 1.625rem;
                    height: 1.625rem;
                    margin-right: 0.5625rem;
                  "
                  :src="item.avatar"
                  fit="cover"
                ></el-image>
                <div class="name">{{ item.username }}</div>
              </div>
            </div>
            <div class="font-14 text-666666 text-ellipsis2">
              {{ item.user_text }}
            </div>
          </div>
          <div class="mb-35">
            <div class="font-14 text-222222 font-weight-bolder mb-20">视频文字稿</div>
            <div class="font-14 text-666666 text-ellipsis2">
              {{ item.original_text }}
            </div>
          </div>
          <div class="font-14 text-222222 flex align-center">
            <el-image
              class="cursorp"
              style="width: 9rem; height: 3.25rem"
              :src="require('@/assets/img/Playimmediately.png')"
              fit="cover"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="flex align-center justify-center py-50 border-top border-ligit-BFBFBF">
      <!-- :total="1000" -->
      <el-pagination
        background
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :page-count="page_nums"
        @current-change="currentChange"
        :current-page="page"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import req from "../../utils/req";
export default {
  data() {
    return {
      type: "index",
      film: [],
      keyword: "",
      Searchhistory: [],
      page_nums: 0,
      page: 1,
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let keyword = this.$route.query.keyword;
        if (keyword) {
          this.keyword = keyword;
        } else {
          this.keyword = "";
        }
        this.getdata2();
        this.getdata();
      },
      deep: true,
    },
  },
  mounted() {
    let keyword = this.$route.query.keyword;
    if (keyword) {
      this.keyword = keyword;
    }
    this.getdata2();
    this.getdata();
  },
  methods: {
    cuttype(type) {
      this.type = type;
      this.getdata2();
      this.getdata();
    },
    // 跳转详情原创
    godetails(id) {
      // console.log(e);creationdetails
      // this.$router.push({
      //   path: "/creationdetails",
      //   query: { type: "creationdetails", moveid: e.id },
      // });
      this.$router.push({
        path: "/creationDetail2",
        query: { type: "creationDetail2", moveid: id },
      });
    },
    // 跳转详情电影
    gofilmdetails(id) {
      this.$router.push({
        path: "/filmdetails",
        query: { type: "filmdetails", moveid: id },
      });
    },
    // 文字高亮
    richNodes(value) {
      if (value.indexOf(this.keyword) !== -1) {
        let reg = new RegExp(this.keyword, "gi");
        return value.replace(reg, `<font style="color:#E7524C">${this.keyword}</font>`);
      } else {
        return value;
      }
    },
    // 点击记录搜索
    history(name) {
      this.$router.push({
        path: "/search",
        query: { type: "search", keyword: name },
      });
    },
    // 搜索
    search() {
      this.$router.push({
        path: "/search",
        query: { type: "search", keyword: this.keyword },
      });
      // this.getdata2();
      // this.getdata();
    },
    // 获取搜索历史记录
    getdata() {
      let that = this;
      req
        .post("index/searchPage", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.Searchhistory = res.data;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    // 获取搜索内容
    getdata2() {
      let that = this;
      req
        .post("index/search", {
          _keywords: that.keyword,
          page: that.page,
          type: that.type,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          console.log(res.data.data);
          that.film = res.data.data;
          that.page_nums = res.data.last_page;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata2();
    },
  },
};
</script>

<style lang="scss">
.searchIndex {
  .el-input-group {
    width: auto;
  }
  .el-input__inner {
    -webkit-appearance: none;
    background-color: #e6e6e6;
    // border-radius: 6.25rem;
    border-top-left-radius: 6.25rem;
    border-bottom-left-radius: 6.25rem;
    border: 0;
    box-sizing: border-box;
    color: #222222;
    display: inline-block;
    font-size: inherit;
    height: 2.1875rem;
    line-height: 2.1875rem;
    outline: 0;
    padding: 0 0.9375rem;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 20.5625rem;
  }
  .search {
    width: 6.5625rem;
    height: 2.1875rem;
    line-height: 2.1875rem;
    background-color: #c9372e;
    color: #fff;
    border-radius: 6.25rem;
    margin-left: -1.125rem;
    cursor: pointer;
  }

  .el-input-group__append,
  .el-input-group__prepend {
    background-color: #c9372e;
    color: #fff;
    vertical-align: middle;
    display: table-cell;
    border: 0;
    border-radius: 6.25rem;
    padding: 0;
    white-space: nowrap;
  }
}
</style>
